<template>
  <div class="customerService_page">
    <div class="box_img">
      <!-- <img src="../../static/bg.png" alt="" /> -->
    </div>
    <div class="container flex justify-content-between">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="title">{{ item.name }}</div>
        <div class="row">
          手机号码: <span class="phoneNum">{{ item.phone }}</span>
        </div>
        <div class="row">
          任职部门: <span>{{ item.department.name }}</span>
        </div>
        <div class="row">
          职位描述: <span>{{ item.work }}</span>
        </div>
        <!-- <div class="row">工作时间: <span>110110110110</span></div> -->
        <div class="img_code">
          <img :src="common.setImgLocation(item.img)" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from "../../request/http";
export default {
  data() {
    return {
      list: [],
    };
  },
  methods: {
    getData() {
      // console.log(http,'---siteInfo')
      this.$http.SalesService().then((res) => {
        this.list = res.data.emp.map((v) => {
          v.img = v.age;
          return v;
        });
        // console.log(this.list, "---res");
      });
    },
  },
  mounted() {
    this.getData();
   
  },
};
</script>
<style lang="scss" scoped>
.customerService_page {
}
.box_img {
  width: 100%;
  height: 370px;
  background-image: url("../../static/bg3.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.container {
  padding-top: 50px;
  width: 80%;
  margin: 0 auto;

  .item {
    width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    .title {
      font-size: 22px;
      font-weight: 800;
      padding: 5px 0;
    }
    .row {
      padding: 5px 0;
      font-size: 16px;
      .phoneNum {
        color: brown;
      }
    }
    .img_code {
      margin: 20px auto;
      // margin-top: 20px;
      width: 150px;
      height: 150px;
      // background-color: pink;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>